/* eslint-disable @typescript-eslint/camelcase */

export const productsQuery = () => ({
  content_type: 'product',
  select: [
    'fields.title',
    'fields.link',
    'fields.price',
    'fields.slug',
    'fields.description',
    'fields.media',
    'fields.showInMainFeed',
  ].join(','),
  'fields.showInMainFeed[ne]': false,
});

export const newestQuery = () => ({
  order: '-sys.updatedAt',
});

// Virtual fields put additional functionality which is not possible
// by using just Contentful
export const bestQuery = () => ({
  order: '-virtual.outboundClicks',
});

export const priceQuery = (price: number) => ({
  'fields.price[lte]': price,
});

export const excludeIdQuery = (id: string) => ({
  'sys.id[ne]': id,
});

export const categoryProductsQuery = (categoryId: string) => ({
  links_to_entry: categoryId,
});
