export const collectionTitle = (
  collectionSize?: number,
  collectionTitle?: string,
  prefix = ''
) => {
  if (!collectionSize) {
    return collectionTitle;
  }
  if (collectionSize < 100) {
    return `${prefix}${collectionSize} ${collectionTitle}`;
  }
  return `${prefix}${collectionSize -
    (collectionSize % 25)}+ ${collectionTitle}`;
};
