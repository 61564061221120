import { merge } from 'lodash';
import React, { Fragment } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import ProductCardGuide from 'src/components/product/card.guide';
import ProductCardGuideMobile from 'src/components/product/card.guide.mobile';
import Divider from 'src/components/widget/divider';
import LoadingIndicator from 'src/components/widget/loading-indicator';
import WideContainer from 'src/components/widget/wide-container';
import DesktopComponent from 'src/hocs/desktop-component';
import MobileComponent from 'src/hocs/mobile-component';
import { usePagination } from 'src/hooks/usePagination';
import { categoryProductsQuery, productsQuery } from 'src/queries';
import { RandomPaginator } from 'src/util/paginator';

import FeedProductListDesktop from './list-feed.desktop';

interface Props {
  contentfulId: string;
  renderHeader: Function;
}

const LIST_TYPE_THRESHOLD = 50;

const randomPaginator = new RandomPaginator();

const GuideProductList = ({ contentfulId, renderHeader }: Props) => {
  const { page, total, seeding, loadNextPage, hasMore } = usePagination({
    paginator: randomPaginator,
    query: merge({}, productsQuery(), categoryProductsQuery(contentfulId)),
  });

  return (
    <>
      {renderHeader && renderHeader(total)}
      {seeding && <LoadingIndicator />}
      <MobileComponent>
        <InfiniteScroll
          hasMore={hasMore}
          loadMore={loadNextPage}
          loader={<LoadingIndicator key="loading" />}
        >
          {page.map((product, index, list) => (
            <Fragment key={product.sys.id}>
              <ProductCardGuideMobile index={index} product={product} />
              {index < list.length - 1 && (
                <WideContainer>
                  <Divider />
                </WideContainer>
              )}
            </Fragment>
          ))}
        </InfiniteScroll>
      </MobileComponent>
      <DesktopComponent>
        {total > LIST_TYPE_THRESHOLD ? (
          <FeedProductListDesktop
            hasMore={hasMore}
            loadMore={loadNextPage}
            products={page}
          />
        ) : (
          <InfiniteScroll
            hasMore={hasMore}
            loadMore={loadNextPage}
            loader={<LoadingIndicator key="loading" />}
          >
            <div className="flex flex-col items-center space-y-20">
              {page.map((product, index) => (
                <ProductCardGuide
                  index={index}
                  key={product.sys.id}
                  product={product}
                />
              ))}
            </div>
          </InfiniteScroll>
        )}
      </DesktopComponent>
    </>
  );
};

export default GuideProductList;
