import React from 'react';
import ReactMarkdown from 'react-markdown';
import IconWhatsapp from 'src/assets/img/ic_whatsapp_white.svg';
import AnchorLink from 'src/components/widget/anchor-link';
import PriceDisplay from 'src/components/widget/price-display';
import { COLOR_WHATSAPP_GREEN } from 'src/constants';
import useIsClient from 'src/hooks/useIsClient';
import useTranslation from 'src/hooks/useTranslation';
import { absoluteSlug, relativeSlug } from 'src/util/product-slug';
import { getWhatsappLink } from 'src/util/social-links';
import { updateProductScore } from 'src/util/update-product-score';
import { IProduct } from 'typings/generated/contentful';

import ProductImage from '../widget/product-image';

interface Props {
  product: IProduct;
  index: number;
}

const ProductCardGuideMobile = (props: Props) => {
  const { product, index } = props;
  const t = useTranslation();
  const isClient = useIsClient();
  const href = absoluteSlug(product.fields, isClient);
  const relativeHref = relativeSlug(product.fields);

  return (
    <div className="relative flex flex-col h-full max-w-sm pt-12 m-auto">
      <div className="relative w-full overflow-hidden">
        <a aria-label={product.fields.title} href={relativeHref}>
          <ProductImage product={product} />
        </a>
      </div>
      <div className="absolute top-0 left-0 px-6 py-8">
        <div className="flex items-center justify-center w-16 h-16 text-2xl text-white rounded title bg-x-black">
          #&nbsp;
          {index + 1}
        </div>
      </div>
      <a aria-label={product.fields.title} href={relativeHref}>
        <div className="relative w-full px-2 py-6 text-2.5xl title">
          {product.fields.title}
        </div>
      </a>
      <div className="w-full px-2 pb-8">
        {
          <ReactMarkdown
            className="text-justify markdown"
            linkTarget="_blank"
            source={product.fields.description}
          />
        }
      </div>
      <div className="flex items-center justify-between w-full px-2 pb-4 font-bold">
        <PriceDisplay icon={false} price={product.fields.price} />
        <div className="flex space-x-2">
          <a
            aria-label="WhatsApp"
            className="px-3 rounded "
            data-action="share/whatsapp/share"
            href={getWhatsappLink({
              pageLink: href,
              title: product.fields.title,
            })}
            style={{ backgroundColor: COLOR_WHATSAPP_GREEN }}
          >
            <IconWhatsapp className="h-full" />
          </a>
          <AnchorLink
            className="btn btn-yellow btn-tall"
            href={product.fields.link}
            onClick={() => updateProductScore(product)}
          >
            {t('toTheProduct')}
          </AnchorLink>
        </div>
      </div>
    </div>
  );
};

export default ProductCardGuideMobile;
