import GatsbyImage from 'gatsby-image';
import React, { useCallback } from 'react';
import Layout from 'src/components/layout/layout';
import GuideProductList from 'src/components/list/list-guide';
import SEO from 'src/components/misc/seo';
import Container from 'src/components/widget/container';
import Divider from 'src/components/widget/divider';
import WideContainer from 'src/components/widget/wide-container';
import { COLOR_GRAY } from 'src/constants';
import DesktopComponent from 'src/hocs/desktop-component';
import MobileComponent from 'src/hocs/mobile-component';
import { collectionTitle } from 'src/util/collection-display-title';
import { getFluidImage } from 'src/util/get-fluid-image';
import { StaticContextType } from 'typings/custom';
import {
  ICustomGiftlist,
  IOccasionItem,
  IProduct,
  IRecipientItem,
} from 'typings/generated/contentful';

interface Props {
  pageContext: {
    reactContext: StaticContextType;
    collection: ICustomGiftlist &
      IOccasionItem &
      IRecipientItem & {
        linkedProducts: IProduct[];
      };
  };
}

const GiftListPage = ({ pageContext }: Props) => {
  const {
    reactContext,
    collection: {
      sys: { id },
      fields: { title, description, heroImage },
    },
  } = pageContext;

  const renderHeader = useCallback((size) => {
    const customTitle = collectionTitle(size, title);
    return (
      <>
        <SEO
          description={description}
          image={heroImage?.fields.file.url}
          title={size ? customTitle : title}
        />
        <div>
          <MobileComponent>
            {!!size && (
              <h1 className="py-4 text-3xl text-center title">{customTitle}</h1>
            )}
            <WideContainer>
              {heroImage && (
                <GatsbyImage
                  backgroundColor={COLOR_GRAY}
                  className="w-screen h-72"
                  fadeIn={false}
                  fluid={getFluidImage(heroImage)}
                />
              )}
            </WideContainer>
            <p className="py-4 pb-16">{description}</p>
            <WideContainer>
              <Divider />
            </WideContainer>
          </MobileComponent>
          <DesktopComponent>
            <div className="grid grid-cols-2 col-gap-4">
              <div className={heroImage ? '' : 'col-span-2'}>
                {!!size && <h1 className="text-5xl title">{customTitle}</h1>}
                <p className="py-4">{description}</p>
              </div>
              {heroImage && (
                <GatsbyImage
                  backgroundColor={COLOR_GRAY}
                  className="w-full rounded h-96"
                  fadeIn={false}
                  fluid={getFluidImage(heroImage)}
                />
              )}
            </div>
            <div className="h-24" />
          </DesktopComponent>
        </div>
      </>
    );
  }, []);

  return (
    <Layout context={reactContext}>
      <SEO
        description={description}
        image={heroImage?.fields.file.url}
        title={title}
      />
      <div className="flex flex-col items-center ">
        <Container>
          <GuideProductList contentfulId={id} renderHeader={renderHeader} />
        </Container>
      </div>
    </Layout>
  );
};

export default GiftListPage;
