import cx from 'classnames';
import React from 'react';

type Props = {
  showOnDesktop?: boolean;
};

const Divider = ({ showOnDesktop }: Props) => {
  return (
    <div
      className={cx('w-full h-1 bg-gray-200', { 'md:hidden': !showOnDesktop })}
    />
  );
};

export default Divider;
