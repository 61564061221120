import { range, shuffle } from 'lodash';
import { IProduct } from 'typings/generated/contentful';

type PaginatorType = 'linear' | 'random';

export abstract class Paginator {
  abstract getNextPage(prevPage: number): number;
  abstract getFirstPage(allPages: number): number;
  abstract mapPage(products: IProduct[]): IProduct[];
  abstract getType(): PaginatorType;
}

export class LinearPaginator extends Paginator {
  getType(): PaginatorType {
    return 'linear';
  }
  getNextPage(prevPage: number): number {
    return prevPage + 1;
  }
  getFirstPage(): number {
    return 0;
  }
  mapPage(products: IProduct[]): IProduct[] {
    return products;
  }
}

export class RandomPaginator extends Paginator {
  getType(): PaginatorType {
    return 'random';
  }
  fetchOrder: number[] = [];
  fetchIndex = 0;
  getNextPage(): number {
    return this.fetchOrder[this.fetchIndex++];
  }
  getFirstPage(allPages: number): number {
    this.fetchOrder = shuffle(range(0, allPages));
    this.fetchIndex = 0;
    return this.getNextPage();
  }
  mapPage(products: IProduct[]): IProduct[] {
    return shuffle(products);
  }
}
