import { Link } from 'gatsby';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import AnchorLink from 'src/components/widget/anchor-link';
import PriceDisplay from 'src/components/widget/price-display';
import ProductImage from 'src/components/widget/product-image';
import useIsClient from 'src/hooks/useIsClient';
import useTranslation from 'src/hooks/useTranslation';
import { getShareLinks } from 'src/util/get-share-links';
import { absoluteSlug, relativeSlug } from 'src/util/product-slug';
import { updateProductScore } from 'src/util/update-product-score';
import { IProduct } from 'typings/generated/contentful';

interface Props {
  product: IProduct;
  index: number;
}
const ProductCardGuide = (props: Props) => {
  const { product, index } = props;
  const t = useTranslation();
  const isClient = useIsClient();
  const href = absoluteSlug(product.fields, isClient);
  const relativeHref = relativeSlug(product.fields);

  const shareLinks = getShareLinks(product, href);

  return (
    <div className="relative flex w-full max-w-3xl space-x-8">
      <div className="relative flex w-1/2">
        <div className="relative w-full overflow-hidden rounded">
          <Link to={relativeHref}>
            <ProductImage className="rounded" product={product} />
          </Link>
        </div>
        <div className="absolute" style={{ top: '2rem', left: '-2.7rem' }}>
          <div className="flex items-center justify-center w-16 h-16 text-4xl text-white rounded title bg-x-black">
            <span className="text-2xl">#</span>
            {index + 1}
          </div>
        </div>
      </div>
      <div className="flex flex-col w-1/2">
        <a aria-label={product.fields.title} href={relativeHref}>
          <div className="relative flex pb-2 text-3xl leading-8 title ">
            {product.fields.title}
          </div>
        </a>
        <div className="flex py-2 space-x-4">
          {shareLinks.map(({ icon: Icon, link, ariaLabel }, index) => (
            <a
              aria-label={ariaLabel}
              className="w-8 h-8 share-popup"
              href={link}
              key={index.toString()}
              rel="noreferrer"
              target="_blank"
            >
              <Icon className="h-full" />
            </a>
          ))}
        </div>
        <div className="flex-1 w-full py-2">
          <span>
            <ReactMarkdown
              className="text-justify markdown"
              linkTarget="_blank"
              source={product.fields.description}
            />
          </span>
        </div>
        <div className="flex items-center justify-between w-full py-2">
          <PriceDisplay icon={false} price={product.fields.price} />
          <AnchorLink
            className="w-48 btn btn-yellow btn-tall"
            href={product.fields.link}
            onClick={() => updateProductScore(product)}
          >
            {t('toTheProduct')}
          </AnchorLink>
        </div>
      </div>
    </div>
  );
};

export default ProductCardGuide;
